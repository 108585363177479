import { memo } from "react";
import { Flex, UnstyledButton } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { ModalSettings } from "@mantine/modals/lib/context";
import { IconX } from "public/icons/IconX";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";
import { getStyles, useStyles } from "./utils";

const ModalHeader = memo(function ModalHeader({ title }: { title: string }): JSX.Element {
    const { classes } = useStyles();
    return (
        <Flex gap={10} mb={30} direction={{ base: "row-reverse", sm: "column" }} justify="space-between">
            <Flex justify="flex-end">
                <UnstyledButton onClick={() => closeAllModals()} w={{ base: 20, sm: 32 }} h={{ base: 20, sm: 32 }}>
                    <IconX color={Color.main_Black} size="100%" />
                </UnstyledButton>
            </Flex>
            <Typography tag="h2" className={classes.title}>
                {title}
            </Typography>
        </Flex>
    );
});
export interface ModalProviderSettings extends Omit<ModalSettings, "withCloseButton"> {
    title: string;
}
interface Options {
    wide?: boolean;
    fullscreen?: boolean;
}
/**
 * Вызов стилизованной модалки
 * @param options для DRY при дизайнерских
 */
export const openDefaultModal = (
    { className, children, title, ...settings }: ModalProviderSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: Options
) => {
    return openModal({
        className,
        styles: (theme) => getStyles(theme, { wide: options?.wide, fullscreen: options?.fullscreen }),
        closeOnClickOutside: false,
        withCloseButton: false,
        children: (
            <>
                <ModalHeader title={title} />
                {children}
            </>
        ),
        ...settings,
    });
};
