import { Box, Flex } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useFeedbackCreate } from "features/footer/ui/FeedbackModal/api/hooks";
import { $FeedbackCreate, FeedbackCreate } from "features/footer/ui/FeedbackModal/api/types";
import { FormikConfig } from "formik";
import { IconBuildings } from "public/icons/IconBuildings";
import { IconEnvelopeSimple } from "public/icons/IconEnvelopeSimple";
import { IconPhone } from "public/icons/IconPhone";
import { IconUserCircle } from "public/icons/IconUserCircle";
import { Form } from "shared/ui/Form";
import { Button } from "shared/ui/Forms/Styled";
import { FInput } from "shared/ui/Input/FInput";
import { openDefaultModal } from "shared/ui/ModalProvider/ModalProvider";
import FTextarea from "shared/ui/Textarea/FTextarea";
import Typography from "shared/ui/Typography/Typography";
import { syncErrors } from "shared/utils";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useStyles } from "./styles";

function FeedbackModal(): JSX.Element {
    const { mutate, isLoading } = useFeedbackCreate();
    const { classes } = useStyles();
    const cfg: FormikConfig<FeedbackCreate> = {
        initialValues: {
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
        },
        validationSchema: toFormikValidationSchema($FeedbackCreate),
        onSubmit: (values, formikHelpers) => {
            mutate(values, {
                onError: (error) => syncErrors(error, formikHelpers),
                onSuccess: () => closeAllModals(),
            });
        },
    };
    return (
        <Form {...cfg}>
            <Flex direction="column" h={{ base: "calc(100dvh - 85px)", sm: "unset" }}>
                <Typography tag="osRegular" color="main_MainDarkGray">
                    Нам важно Ваше мнение о качестве нашей работы. Если у Вас есть предложения, пожелания или Вы
                    остались недовольны качеством обслуживания, воспользуйтесь формой, приведенной ниже.
                </Typography>
                <Flex mt={30} direction="column" gap={15}>
                    <Box className={classes.modal}>
                        <FInput name="name" icon={<IconUserCircle />} label="Имя и Фамилия" withAsterisk />
                        <FInput name="email" icon={<IconEnvelopeSimple size={20} />} label="E-mail" withAsterisk />
                        <FInput type="mask" name="phone" icon={<IconPhone size={20} />} label="Телефон" withAsterisk />
                        <FInput name="company" icon={<IconBuildings size={20} />} label="Компания" withAsterisk />
                    </Box>
                    <FTextarea name="message" label="Сообщение" h={{ md: 150 }} withAsterisk />
                </Flex>
                <Flex gap={15} pt={50} mt="auto">
                    <Button
                        label="Отправить"
                        variant="filled"
                        type="submit"
                        loading={isLoading}
                        w={{ base: "100%", sm: "unset" }}
                    />
                    <Button
                        label="Отменить"
                        variant="light"
                        onClick={() => closeAllModals()}
                        w={{ base: "100%", sm: "unset" }}
                    />
                </Flex>
            </Flex>
        </Form>
    );
}

export const openFeedbackModal = (fullScreen?: boolean) => {
    return openDefaultModal(
        {
            title: "Написать директору",
            children: <FeedbackModal />,
            fullScreen,
        },
        {
            wide: true,
            fullscreen: fullScreen,
        }
    );
};
export default FeedbackModal;
