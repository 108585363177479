import { useEffect } from "react";
import { Burger, Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useRouter } from "next/router";
import { MenuContent } from "../MenuContent";
import { useToggleMenuStyles } from "./styles";

export function ToggleMenu() {
    const { classes } = useToggleMenuStyles();
    const [opened, { open, close }] = useDisclosure(false);
    const router = useRouter();

    useEffect(() => {
        router.events.on("routeChangeStart", close);

        return () => {
            router.events.off("routeChangeStart", close);
        };
    }, []);

    return (
        <>
            <Burger opened={opened} onClick={open} />
            <Drawer opened={opened} onClose={close} classNames={classes}>
                <MenuContent />
            </Drawer>
        </>
    );
}
