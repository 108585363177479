import { Box } from "@mantine/core";
import { useRouter } from "next/router";
import { SlugStaticPage } from "shared/api/pages/types";
import Typography from "shared/ui/Typography/Typography";
import { useStyles } from "./styles";

function PersonalDataAccess(): JSX.Element {
    const { classes } = useStyles();
    const router = useRouter();
    const handleClickOnAgreement = (): void => {
        router.push({ pathname: "/page/[slug]", query: { slug: SlugStaticPage.CONSENT_TO_PERSONAL_DATA_PROCESSING } });
    };
    const handleClickOnPolicy = (): void => {
        router.push({ pathname: "/page/[slug]", query: { slug: SlugStaticPage.PERSONAL_DATA_PROCESSING_POLICY } });
    };

    return (
        <Box>
            <Typography tag="osSmallRegular">
                <span className={classes.link} onClick={handleClickOnAgreement}>
                    Согласие на обработку персональных данных
                </span>
                <span> | </span>
                <span className={classes.link} onClick={handleClickOnPolicy}>
                    Политика обработки персональных данных
                </span>
            </Typography>
        </Box>
    );
}

export default PersonalDataAccess;
