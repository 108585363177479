import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
    link: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));
