import { MutableRefObject, useEffect, useRef } from "react";
import { useDebouncedState } from "@mantine/hooks";

const DISPLAYED_ITEM_SIZE = 40;
const DISPLAYED_ITEMS_COUNT = 6;

export const useItemsCountResizable: (
    itemSize?: number,
    defaultCount?: number,
    wait?: number,
    dimension?: "width" | "height"
) => [number, MutableRefObject<HTMLDivElement | null>] = (
    itemSize = DISPLAYED_ITEM_SIZE,
    defaultCount = DISPLAYED_ITEMS_COUNT,
    wait = 1500,
    dimension = "width"
) => {
    const [itemsCount, setItemsCount] = useDebouncedState<number>(defaultCount, wait);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (itemSize === 0) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            if (entries.length > 0) {
                const entry = entries[0];
                let count = entry.contentRect[dimension] / itemSize;
                const floored = Math.floor(count);
                count = count - floored > 0.7 ? count : count - 1;
                setItemsCount(count);
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => resizeObserver.disconnect();
    }, [itemSize, dimension]);

    return [itemsCount, ref];
};
