import { Box, packSx } from "@mantine/core";
import { BoxProps } from "@mantine/core/lib/Box/Box";
import { contentMaxWidth, mSidePadding, sidePadding } from "styles/index";

function MaxWidthLayout({ sx, ...props }: BoxProps): JSX.Element {
    return (
        <Box
            sx={[
                {
                    position: "relative",
                    maxWidth: contentMaxWidth + sidePadding * 2,
                    paddingLeft: sidePadding,
                    paddingRight: sidePadding,
                    marginLeft: "auto",
                    marginRight: "auto",
                    "@media (max-width: 1230px)": {
                        width: "100%",
                    },

                    "@media (max-width: 576px)": {
                        paddingLeft: mSidePadding,
                        paddingRight: mSidePadding,
                    },

                    "@media (max-width: 320px)": {
                        paddingLeft: mSidePadding,
                        paddingRight: mSidePadding,
                    },
                },
                ...packSx(sx),
            ]}
            {...props}
        />
    );
}
export default MaxWidthLayout;
