import { createStyles, MantineTheme } from "@mantine/core";

export const getStyles = (theme: MantineTheme, { wide, fullscreen }: { wide?: boolean; fullscreen?: boolean }) => ({
    root: {
        ...(fullscreen
            ? {
                  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                      borderRadius: "8px 8px 0px 0px !important",

                      "&::before": {
                          content: '""',
                          backgroundColor: "black",
                          position: "fixed" as const,
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                      },
                  },
              }
            : {}),
    },
    modal: {
        boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.06)",
        background: theme.colors.second_white[0],
        borderRadius: 6,
        width: fullscreen ? "100%" : wide ? 800 : 500,
        padding: "30px 30px 50px 30px !important",
        ...(fullscreen
            ? {
                  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                      borderRadius: "8px 8px 0px 0px !important",
                      padding: "20px 10px 20px 10px !important",
                  },
              }
            : {}),
    },
    inner: {
        alignItems: "center",
    },
    overlay: {
        background: theme.colors.main_Black[0],
        opacity: 0.8,
    },
});

export const useStyles = createStyles((theme) => ({
    title: {
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "20px",
            letterSpacing: "0.009em",
        },
    },
}));
