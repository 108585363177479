import { createStyles, MantineTheme } from "@mantine/core";

export const useBannerStyles = createStyles((theme: MantineTheme) => ({
    root: {
        background: theme.colors.main_Black,
        height: `${40}px`,
        [`@media (max-width: ${320}px)`]: {
            height: `${60}px`,
        },
    },
    slide: {
        height: `${40}px`,
        [`@media (max-width: ${320}px)`]: {
            height: `${60}px`,
        },
    },
}));
