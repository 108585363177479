import { Dispatch, memo, SetStateAction, useEffect, useState } from "react";
import { Flex, FocusTrap, Menu } from "@mantine/core";
import { useRouter } from "next/router";
import { IconMagnifyingGlass } from "public/icons/IconMagnifyingGlass";
import { Button } from "shared/ui/Forms/Styled";
import { StyledInput } from "shared/ui/Input/StyledInput";

interface Props {
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    onKeyDownEnter?: () => void;
    /**
     *проп определяет нужно ли рендерить <Menu.Item> для закрытия меню при нажатии на поиск
     */
    menu?: boolean;
    isLoading?: boolean;
}
export const SearchBar = memo(function SearchBar({ search, setSearch, menu, isLoading, onKeyDownEnter }: Props) {
    const router = useRouter();

    const findButton = (
        <Button
            label="Найти"
            disabled={!search}
            href={{
                pathname: "/search",
                query: { search },
            }}
            loading={isLoading}
            w={{ base: "100%", sm: "unset" }}
        />
    );

    const [shouldFocus, setShouldFocus] = useState(false);

    useEffect(() => {
        setShouldFocus(true);
    }, []);

    return (
        <Flex justify="flex-end" gap={{ base: 10, sm: 30 }} direction={{ base: "column", sm: "row" }}>
            <FocusTrap active={shouldFocus}>
                <StyledInput
                    placeholder="Поиск"
                    onClear={() => setSearch("")}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    value={search}
                    variant="search"
                    autoFocus
                    icon={<IconMagnifyingGlass />}
                    onKeyDown={(e) => {
                        if (e.code !== "Enter") {
                            return null;
                        }

                        router.push({ pathname: "/search", query: { search } });
                        onKeyDownEnter?.();
                    }}
                />
            </FocusTrap>
            {menu ? (
                <Menu.Item closeMenuOnClick p={0} w="auto">
                    {findButton}
                </Menu.Item>
            ) : (
                findButton
            )}
        </Flex>
    );
});
