import { memo } from "react";
import { ScrollArea, Space, Stack } from "@mantine/core";
import { Product } from "shared/api/products/types";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";

interface Props {
    count: number;
    items: Product[];
    onClickItem: (item: Product) => void;
}

export const QuickSearchResults = memo(function QuickSearchResults({ items, count, onClickItem }: Props) {
    if (!count) {
        return <Space h={70} />;
    }

    return (
        <Stack>
            <Typography mb={15}>Найдено совпадений: {count}</Typography>

            <ScrollArea.Autosize
                offsetScrollbars
                scrollbarSize={6}
                type="always"
                maxHeight="calc(100dvh - 380px)"
                placeholder=""
                onPointerEnterCapture={() => undefined}
                onPointerLeaveCapture={() => undefined}
                styles={{
                    scrollbar: {
                        '&[data-orientation="vertical"] .__mantine-ref-thumb': {
                            backgroundColor: Color.black,
                        },
                    },
                }}>
                <Stack justify="flex-start">
                    {items.map((product) => {
                        const linkProductOffer = `/catalog/${product.slugCategory}/${product.slug}/${product.offer?.slug}`;

                        const handleClick = () => onClickItem(product);

                        return (
                            <StyledLink
                                key={product.id}
                                href={linkProductOffer}
                                style={{ justifyContent: "flex-start", padding: "6px 12px" }}
                                onClick={handleClick}>
                                <Typography>{product.name}</Typography>
                            </StyledLink>
                        );
                    })}
                </Stack>
            </ScrollArea.Autosize>
        </Stack>
    );
});
