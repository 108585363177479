import { useMemo, useRef } from "react";
import { Carousel } from "@mantine/carousel";
import { MediaQuery } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { Slide } from "entities/banner/ui/Slide";
import { LinkProps } from "next/link";
import { useBanner } from "shared/api/banner/hooks/queries/useBanner";
import { useBannerStyles } from "./styles";

export function Banner() {
    const { classes } = useBannerStyles();
    const { data = [] } = useBanner();
    const formattedBannerSlides = useMemo(
        () =>
            data?.map((item) => ({
                text: item.content,
                href: item?.redirect_path as LinkProps["href"],
                id: item.id.toString(),
            })),
        [data]
    );
    const autoplayRef = useRef(Autoplay({ delay: 5000 }));

    return (
        <MediaQuery query="print" styles={{ display: "none" }}>
            <Carousel
                plugins={[autoplayRef.current]}
                onMouseEnter={() => {
                    autoplayRef.current.stop();
                }}
                onMouseLeave={() => {
                    autoplayRef.current.play();
                }}
                onSlideChange={() => {
                    autoplayRef.current.play();
                }}
                withIndicators={false}
                withControls={false}
                loop
                align="center"
                skipSnaps={true}
                classNames={classes}>
                {formattedBannerSlides.map((item) => (
                    <Slide {...item} key={item.id} />
                ))}
            </Carousel>
        </MediaQuery>
    );
}
