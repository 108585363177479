import { createStyles, MantineTheme } from "@mantine/core";

export const useToggleMenuStyles = createStyles((theme: MantineTheme) => {
    return {
        triggerButton: {
            [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                ["span:first-of-type"]: {
                    margin: 0,
                },
                ["span:last-of-type"]: {
                    display: "none",
                },
                paddingLeft: "12px",
                paddingRight: "12px",
            },
        },
        drawer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            marginTop: `${10}px`,
            overflowY: "scroll",

            drawerHeader: {
                background: "green",
            },
        },
        header: {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "20px 10px",
            width: "100%",
            svg: {
                color: theme.colors.main_Black,
                height: "40px",
                width: "40px",
            },
        },
        body: {
            padding: "10px",
            height: "calc(100% - 84px)",
            overflow: "scroll",
        },
        headers: {
            position: "fixed",
        },
    };
});
